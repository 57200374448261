html {
  height: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
}

.s4r-body-class {
  overflow-y: hidden;
}

.home-dashboard__chart__tooltip {
  display: flex;
  padding: 5px 6px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #4262ff;
  border-radius: 4px;
  min-width: 80px;
  min-height: 40px;
  box-sizing: border-box;
  background: #fff;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.home-dashboard__chart__tooltip__value {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #4262ff;
}

.home-dashboard__chart__tooltip__date {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #5a6981;
}

.home-dashboard__chart__tooltip__series-name {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #5a6981;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  align-self: start;
}

.series__color {
  border-radius: 50%;
  height: 1em;
  width: 1em;
}

.release_notes_container {
  padding: 0 1.5rem;
}
